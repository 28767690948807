<template>
  <section>
    <div>
      <template v-if="!fromQuickAction">
        <span v-if="isActive" class="setting-icon" @click="openSettings">
          <i class="el-icon-s-tools" />
        </span>
        <el-link
          :loading="loading"
          v-if="
            data &&
            data.action_button_type &&
            data.action_button_type == 'HYPERLINK'
          "
          :style="hideStyles ? getGalleryStyles : getStyle"
          @click="action"
          :disabled="checkReadonly"
          >{{ label || data.label }}</el-link
        >
        <el-button
          :loading="loading"
          :style="hideStyles ? '' : getStyle"
          :round="isRound"
           :size="getSize"
          v-else
          @click="action"
          :disabled="checkReadonly"
          >{{ label || data.label }}</el-button
        >
      </template>
      <dialog-component
        :title="
          viewDataOnly || viewDataAndAdd
            ? 'Related ' + (relationshipDetails.relationship_title || 'data')
            : allowNewAdd
            ? 'Add or assign'
            : 'Assign'
        "
        :visible="openRelationalModal"
        @before-close="resetAddModal"
        :containerWidth="getIsMobile ? '100%' : '25vw'"
        :containerMaxHeight="'90%'"
      >
        <!-- <el-scrollbar wrap-style="max-height: 1000px; padding-right: 10px;"> -->
        <quick-entity-filter
          v-if="relationshipDetails && openRelationalModal"
          :entityId="relationshipDetails.relationship_id"
          :relationshipDetails="relationshipDetails"
          :allowNewAdd="allowNewAdd"
          :actionButtonField="data"
          :viewDataOnly="viewDataOnly"
          :viewDataAndAdd="viewDataAndAdd"
          :allowNewAddOnly="allowNewAddOnly"
          @quickAddClose="resetAddModal(true)"
          @entityDataSelect="updateEntityData"
          :parentFields="fieldsData"
          :parentForm="form"
          :parentEntityData="entityData"
          style="padding:10px"
        ></quick-entity-filter>
        <!-- </el-scrollbar> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetAddModal">
            {{ viewDataOnly || viewDataAndAdd ? "Close" : "Cancel" }}
          </el-button>
          <el-button
            v-if="!viewDataOnly && !viewDataAndAdd"
            type="primary"
            @click="establichRelationship"
            :disabled="checkDisabled"
            :loading="loading"
            >Save</el-button
          >
        </span>
      </dialog-component>
      <dialog-component
        title="Add entity data"
        :visible="openOtherEntityModal"
        @before-close="resetOtherEntityModal"
        :containerMaxHeight="'70%'"
      >
        <quickEntityAddWidget
          :entityId="selectedOtherEntityId"
          :actionButtonField="data"
          :currentEntity="currentEntity"
          :openNewAdd="true"
          :relationshipDetails="[]"
          :detailed_add="true"
          @onNewEntity="resetOtherEntityModal"
          :parentFields="fieldsData"
          :parentForm="form"
          :parentEntityData="entityData"
          :isFromAddOtherEntity="isFromAddOtherEntity"
          :otherEntityDataId="parent_entityDataId"
          :isUpdateEntityData="isUpdateEntityData"
        ></quickEntityAddWidget>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetOtherEntityModal">Cancel</el-button>
          <el-button type="primary">Save</el-button>
        </span>
      </dialog-component>
    </div>
  </section>
</template>

<script>
import templateBuilderHelper from "@/mixins/templateBuilderHelper";
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
import { makeSlug } from "../../../helpers/appHelper";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import EntityHelper from "@/mixins/EntitiesHelper";
import JSZip from "jszip";
import axios from "axios";
import { postAPICall } from "@/helpers/httpHelper";
// import { bus } from "../../../main";
export default {
  name: "templates-formComponentsExecute-ActionButtonExecute",
  props: [
    "data",
    "label",
    "form",
    "isActive",
    "workflowDataId",
    "entityId",
    "entityDataId",
    "fieldsData",
    "parentFields",
    "checkIsDisabled",
    "hideStyles",
    "isView",
    "selectedTableRows",
    "fromQuickAction",
    "entity_type",
    "entityData",
    "selfTemplateId",
    "templateName",
  ],
  mixins: [templateBuilderHelper, userPermissionsHelper, EntityHelper],
  components: {
    QuickEntityFilter: () => import("../../widgets/EntityAddSelectWidget.vue"),
    quickEntityAddWidget: () =>
      import("@/components/widgets/quickEntityAddWidget"),
  },
  computed: {
    ...mapGetters("navigationOpen",["getIsMobile"]),
    ...mapGetters("formBuilders", ["getPublicformBuilderDetails"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getEntityAllRelationshipData",
      "getEntityRelationshipDataAssignStatus",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveContactType",
      "getAuthenticationStatus",
    ]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
      "getDuplicateEntityData",
      "getDuplicateSelectedEntityData",
    ]),
    ...mapGetters("entities", ["getEntityById"]),
    ...mapGetters("entitiesData", ["getCreateDocumentForEntityData"]),
    getButtonColor() {
      if (this.data?.styles?.label_color) {
        return this.data.styles.label_color;
      }
      return "black";
    },
    getBackgroundColor() {
      if (this.data?.styles?.input_background) {
        return this.data.styles.input_background;
      }
      return "#285ED3";
    },
    getGalleryStyles() {
      return "font-size: 12px; color: #409EFF;";
    },
    getStyle() {
      if (this.hideStyles) {
        return "";
      }
      let style = `color:${this.getButtonColor};`;
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        if (this.data?.styles?.font?.name) {
          borderStyle += "font-family:" + this.data.styles.font.name + ";";
        }
      }
      if (this.data?.action_button_type != "HYPERLINK") {
        style += `background-color: ${this.getBackgroundColor} !important;`;
      }
      style += borderStyle;
      return style;
    },
    getSize(){
      if (this.data?.styles?.button_size == "Mini") {
        return "mini";
      }
      else if(this.data?.styles?.button_size == "Small"){
        return "small";
      }
      else if(this.data?.styles?.button_size == "Large"){
        return "large";
      }
      return "small";
    },
    isRound() {
      if (this.data?.styles?.button_type == "Rounded") {
        return true;
      }
      return false;
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      return false;
    },
    checkDisabled() {
      return this.selectedChildEntitiesData &&
        this.selectedChildEntitiesData.length
        ? false
        : true;
    },
  },
  data() {
    return {
      validations: [],
      showRepeatingTemplate: false,
      tableData: [],
      selectedTemplateFields: [],
      pageLoading: false,
      entitiesData: [],
      actionButtons: [],
      loading: false,
      tableHeaders: [],
      repeatableTemplate: {},
      openModal: false,
      modalData: null,
      populatedEntitiesData: [],
      isExecute: false,
      inLineForm: {},
      editIndex: -1,
      entitie: [],
      entityFields: [],
      hasLabel: false,
      otherActionUrl: "",
      allowedRelationships: [],
      actions: [],
      navigationAction: null,
      userEntityDataId: "",
      activeContactType: "",
      openRelationalModal: false,
      relationshipsData: [],
      relationshipDetails: {},
      selectedChildEntitiesData: [],
      existedRelationshipsMap: [],
      authenticationStatus: false,
      allowNewAddOnly: false,
      allowNewAdd: false,
      viewDataOnly: false,
      viewDataAndAdd: false,
      unAssignedFieldDetails: [],
      openOtherEntityModal: false,
      currentEntity: null,
      isFromAddOtherEntity: false,
      parent_entityId: null,
      parent_entityDataId: null,
      fieldValue: null,
      checkRelationshipsOnForm: null,
      currentDataId: this.entityDataId,
      checkEditData: null,
      otherEntityDataId: null,
      isUpdateEntityData: false,
      selectedOtherEntityId: null,
      loadingText: "",
      checkDownloadData: null,
      isFromMenuTemplate: false,
    };
  },
  mounted() {
    if (this.getActiveContactType?.contact_type?._id) {
      this.activeContactType = this.getActiveContactType?.contact_type?._id;
      this.userEntityDataId = this.getActiveContactType.account_data_id;
    }
    if (this.$route?.name == "ViewMenuTemplate") {
      this.isFromMenuTemplate = true;
    }
    let checkNavigation = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "NAVIGATION"
    );
    let checkRelationships = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "ESTABLISH_RELATIONSHIP"
    );
    let checkIsAdd = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "ADD_OTHER_ENTITY"
    );
    let checkIsEdit = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "EDIT_OTHER_ENTITY"
    );
    let checkIsDownload = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "DOWNLOAD_DATA"
    );
    if (checkRelationships) {
      if (checkRelationships.relationship_other_entity == "self_entity") {
        this.parent_entityId = this.data.parent_entityId;
        this.parent_entityDataId = this.data.parent_entityDataId;
      } else {
        if (checkRelationships?.relationship_other_entity) {
          let [e, f] = checkRelationships.relationship_other_entity.split("#");
          if (e && f) {
            this.parent_entityId = e;
            this.parent_entityDataId = this.form[f];
            this.fieldValue = this.form[f];
            this.checkRelationshipsOnForm = checkRelationships;
          }
        }
      }
    }
    if (this.parent_entityId) {
      this.checkEntityRelationships(
        this.parent_entityId,
        this.parent_entityDataId
      );
    }
    if (checkIsAdd && checkIsAdd?.selected_other_entity) {
      this.selectedOtherEntityId = checkIsAdd.selected_other_entity;
    }
    if (
      checkIsEdit &&
      checkIsEdit?.selected_other_entity_for_edit?.includes("#")
    ) {
      this.checkEditData = checkIsEdit;
      this.isUpdateEntityData = true;
      if (this.checkEditData?.selected_other_entity_for_edit?.split("#")[0]) {
        this.selectedOtherEntityId =
          this.checkEditData.selected_other_entity_for_edit.split("#")[0];
      }
      this.setAndUpdateData();
    }
    if (checkIsDownload) {
      if (checkIsDownload.selected_entity_for_download == "self_entity") {
        this.parent_entityId = this.data.parent_entityId;
        this.parent_entityDataId = this.data.parent_entityDataId;
      } else if (
        checkIsDownload.selected_entity_for_download == "current_template"
      ) {
        //current template do nothing
      } else {
        if (checkIsDownload.selected_entity_for_download) {
          this.checkDownloadData = checkIsDownload;
          this.setAndUpdateData();
        }
      }
    }
    if (checkNavigation) {
      this.navigationAction = checkNavigation;
      switch (checkNavigation?.action_button_action_navigation_type) {
        case "OPEN_FORM_BUILDER":
          this.fetchFormbuilder(checkNavigation.action_button_formbuilder_id);
          break;
        case "OPEN_DOCUMENT_TEMPLATE_BUILDER":
          this.fetchDocumentTemplate(
            checkNavigation.action_button_document_template_id
          );
          break;
        case "OPEN_ENTITY":
          this.otherActionUrl =
            appConfig.APP_URL +
              "/entity/" +
              checkNavigation.action_button_entity_id +
              (checkNavigation?.action_button_entity_filter_id && checkNavigation.action_button_entity_filter_id
              ? `?filter=${checkNavigation.action_button_entity_filter_id}`
              : "");
          break;
        case "OPEN_ENTITY_VIEW":
          this.otherActionUrl =
            appConfig.APP_URL +
            "/entity-views/view/" +
            checkNavigation.action_button_entity_view_id;
          break;
      }
    }
    //remove navigation action
    this.actions = (this.data.actions || []).filter(
      (e) => e.action_button_action_type != "NAVIGATION"
    );
    if (this.fromQuickAction) {
      this.action();
    }
  },
  watch: {
    form: {
      handler() {
        if (this.checkRelationshipsOnForm) {
          let [e, f] =
            this.checkRelationshipsOnForm.relationship_other_entity.split("#");
          if (this.fieldValue !== this.form[f]) {
            this.parent_entityId = e;
            this.parent_entityDataId = this.form[f];
            this.fieldValue = this.form[f];
            if (this.parent_entityDataId) {
              this.checkEntityRelationships(
                this.parent_entityId,
                this.parent_entityDataId
              );
            }
          }
        }
        if (this.checkEditData || this.checkDownloadData) {
          this.setAndUpdateData();
        }
      },
      deep: true,
    },
  },
  methods: {
    setAndUpdateData() {
      if (this.checkEditData) {
        let [e, f] =
          this.checkEditData.selected_other_entity_for_edit.split("#");
        if (this.fieldValue !== this.form[f]) {
          this.parent_entityId = e;
          this.parent_entityDataId = this.form[f];
          this.fieldValue = this.form[f];
          if (this.parent_entityDataId) {
            this.checkEntityRelationships(
              this.parent_entityId,
              this.parent_entityDataId
            );
          }
        }
      } else if (this.checkDownloadData) {
        let [e, f] =
          this.checkDownloadData.selected_entity_for_download.split("#");
        if (this.fieldValue !== this.form[f]) {
          this.parent_entityId = e;
          this.parent_entityDataId = this.form[f];
          this.fieldValue = this.form[f];
        }
      }
    },
    async updateRelationships() {
      let relationEntity = this.actions.find(
        (e) =>
          e.action_button_action_type == "ESTABLISH_RELATIONSHIP" &&
          e.relational_entity_one
      );
      if (
        this.activeContactType == relationEntity?.relational_entity_one &&
        this.userEntityDataId
      ) {
        this.selectedChildEntitiesData = [this.userEntityDataId];
        this.establichRelationship();
      } else if (this.relationshipDetails) {
        if (relationEntity.action_button_relationship_type == 3) {
          this.allowNewAdd = true;
        }
        if (relationEntity.action_button_relationship_type == 2) {
          this.allowNewAddOnly = true;
          this.allowNewAdd = true;
        }
        if (relationEntity.action_button_relationship_type == 4) {
          this.viewDataOnly = true;
          this.allowNewAddOnly = false;
          this.allowNewAdd = false;
        }
        if (relationEntity.action_button_relationship_type == 5) {
          this.viewDataAndAdd = true;
        }
        this.openRelationalModal = true;
      } else {
        if (this.navigationAction) {
          this.checkNavigationAction(this.navigationAction);
        }
      }
    },
    async establichRelationship() {
      this.loading = true;
      let data;
      if (this.relationshipDetails.owner_type == "PARENT") {
        data = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
          parent_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_data_ids: this.selectedChildEntitiesData,
        };
      } else {
        if (this.relationshipDetails.parent_relation_type == "MANY") {
          data = {
            parent_entity_id: this.relationshipDetails.child_entity_id,
            child_entity_id: this.relationshipDetails.parent_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_data_ids: this.selectedChildEntitiesData,
          };
        } else {
          data = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id: this.selectedChildEntitiesData[0],
            child_entity_data_ids: [
              this.relationshipDetails.parent_entity_data_id,
            ],
          };
        }
        if (
          this.relationshipDetails.representation == "FIELD" &&
          this.unAssignedFieldDetails &&
          this.unAssignedFieldDetails.length
        ) {
          data.assign_field_data = [];
          this.selectedChildEntitiesData.forEach((entity_data_id) => {
            this.unAssignedFieldDetails.forEach((field) => {
              data.assign_field_data.push({
                ...field,
                entity_data_id,
              });
            });
          });
        }
      }
      await this.$store.dispatch(
        "entityRelationships/assignEntityRelationshipData",
        data
      );

      if (this.getEntityRelationshipDataAssignStatus) {
        this.loading = false;
        this.$message.success(
          `${this.relationshipDetails.relationship_title} data added successfully`
        );
        this.resetAddModal(true);
      } else {
        this.loading = false;
      }
    },
    updateEntityData(data) {
      if (data?.value) {
        if (typeof data.value == "object" && data.value.length) {
          this.selectedChildEntitiesData = [...data.value];
        } else {
          this.selectedChildEntitiesData = [data.value];
        }
      }
      if (data.unAssignedFieldDetails) {
        this.unAssignedFieldDetails = data.unAssignedFieldDetails;
      }
      if (data.update) {
        this.establichRelationship();
      }
    },
    resetAddModal(isRefresh) {
      if (isRefresh) {
        let relationEntity = this.actions.find(
          (e) =>
            e.action_button_action_type == "ESTABLISH_RELATIONSHIP" &&
            e.relational_entity_one
        );
        if (this.navigationAction) {
          this.checkNavigationAction(this.navigationAction);
        } else if (relationEntity?.call_back) {
          this.afterAction(relationEntity);
        }
      }
      this.selectedChildEntitiesData = [];
      this.openRelationalModal = false;
      this.$emit("closeModal");
    },
    async getExistedRelationshipsData(entityId, entityDataId) {
      await this.$store.dispatch(
        "entityRelationships/fetchEntityAllRelationshipData",
        {
          parent_entity_id: entityId,
          parent_entity_data_id: entityDataId,
        }
      );
      if (this.getEntityAllRelationshipData) {
        this.setEntityRelationshipMapData(
          this.getEntityAllRelationshipData,
          this.relationshipDetails.relationship_id
        );
      }
    },
    setEntityRelationshipMapData(reationshipData, entityId) {
      this.existedRelationshipsMap = [];
      (reationshipData || []).forEach((relation) => {
        if (
          relation?.relationship_id == entityId &&
          relation?.entity_data_ids
        ) {
          this.existedRelationshipsMap = [
            ...this.existedRelationshipsMap,
            ...relation.entity_data_ids.filter((e) => e),
          ];
        }
      });
    },
    async checkEntityRelationships(entityId, entityDataId) {
      this.allowedRelationships = [];
      await Promise.all([
        this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
          entity_id: entityId,
        }),
      ]);
      if (this.getEntityRelationships) {
        this.setRelationshipDetails(entityId, entityDataId);
      }
    },
    setRelationshipDetails(entityId, entityDataId) {
      this.relationshipsData = [...(this.getEntityRelationships || [])];
      // we have to add type owner of relationship
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == entityId) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = entityDataId;
        return e;
      });

      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });
      // this.relationshipsData = this.relationshipsData.filter(
      //   (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      // );
      let relationEntity = this.actions.find(
        (e) =>
          e.action_button_action_type == "ESTABLISH_RELATIONSHIP" &&
          e.relational_entity_one
      );
      if (relationEntity) {
        this.relationshipDetails = this.relationshipsData.find(
          (e) => e.relationship_id == relationEntity.relational_entity_one
        );
        if (this.relationshipDetails) {
          this.setAllowOne();
          if (entityDataId) {
            this.getExistedRelationshipsData(entityId, entityDataId);
          }
        }
        if (this.fromQuickAction) {
          this.action();
        }
      }
    },
    setAllowOne() {
      this.relationshipDetails.allowOne = null;

      if (this.relationshipDetails.representation == "TAB") {
        if (
          (this.relationshipDetails.owner_type == "CHILD" &&
            this.relationshipDetails.parent_relation_type == "ONE") ||
          (this.relationshipDetails.owner_type == "PARENT" &&
            this.relationshipDetails.child_relation_type == "ONE")
        ) {
          this.relationshipDetails.allowOne = true;
        } else {
          this.relationshipDetails.allowOne = false;
        }
      } else {
        if (this.relationshipDetails.parent_relation_type == "MANY") {
          this.relationshipDetails.allowOne = false;
        } else {
          this.relationshipDetails.allowOne = true;
        }
      }
    },
    async fetchDocumentTemplate(template_id) {
      if (template_id && template_id.includes("#")) {
        this.otherActionUrl =
          appConfig.APP_URL +
          `/company-documents/${template_id.split("#")[0]}/configure/template/${
            template_id.split("#")[1]
          }/preview`;
      }
    },
    async fetchFormbuilder(formbuilder_id) {
      this.loading = true;
      this.otherActionUrl = "";
      await this.$store.dispatch("formBuilders/fetchPublicWorkFlowById", {
        id: formbuilder_id,
      });
      if (this.getPublicformBuilderDetails?._id) {
        this.loading = false;
        this.otherActionUrl =
          appConfig.APP_URL +
          "/public/fb" +
          "/" +
          `${this.getPublicformBuilderDetails.code}` +
          "/" +
          `${this.getPublicformBuilderDetails.steps[0].slug}` +
          "/" +
          `${this.getPublicformBuilderDetails._id}` +
          "/auth/verify";

        if (this.getAuthenticatedUser?.is_contact) {
          this.otherActionUrl = "";

          this.otherActionUrl =
            appConfig.APP_URL +
            "/public/fb" +
            "/" +
            `${this.getPublicformBuilderDetails.code}` +
            "/" +
            `${this.getPublicformBuilderDetails.steps[0].slug}` +
            "/" +
            `${this.getPublicformBuilderDetails._id}` +
            "/vp/" +
            this.getAuthenticatedUser._id;
          const queryParams = {
            application_user: "true",
          };

          // Convert the query parameters object to a query string
          const queryString = Object.keys(queryParams)
            .map(
              (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                  queryParams[key]
                )}`
            )
            .join("&");
          this.otherActionUrl = `${this.otherActionUrl}?${queryString}`;
        }
      } else {
        this.loading = false;
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    openURL(url, action) {
      switch (action.action_button_target_location) {
        case "SELF":
          window.location.href = url;
          break;
        case "NEW_TAB":
          window.open(url, "_blank");
          break;
        case "NEW_WINDOW":
          window.open(url, "MsgWindow", "width=800,height=800");
          break;
        default:
          window.location.href = url;
          break;
      }
    },
    applyFormRules() {
      this.$set(this.form, this.data.key, true);
      this.$emit("applyFormRules");
      let relationAction = (this.actions || []).find(
        (action) => "ESTABLISH_RELATIONSHIP" == action.action_button_action_type
      );
      if (this.navigationAction && !relationAction) {
        this.checkNavigationAction(this.navigationAction);
      }
      // setTimeout(() => {
      //   this.$set(this.form, this.data.key, false);
      // }, 500);
    },
    checkNavigationAction(action) {
      switch (action.action_button_action_navigation_type) {
        case "OPEN_URL":
          if (action.action_button_target_link) {
            if (
              this.isValidURL(action.action_button_target_link) &&
              this.isValidHttpUrl(
                this.getValidUrl(action.action_button_target_link)
              )
            ) {
              let url = this.getValidUrl(action.action_button_target_link);
              this.openURL(url, action);
            } else {
              this.$message.info("Invalid URL");
            }
          } else {
            this.$message.info("No Hyperlink to open");
          }
          break;
        case "OPEN_FORM_BUILDER":
          if (action.action_button_formbuilder_id && this.otherActionUrl) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No form builder selected");
          }
          break;
        case "OPEN_DOCUMENT_TEMPLATE_BUILDER":
          if (
            action.action_button_document_template_id &&
            this.otherActionUrl
          ) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No document template selected");
          }
          break;
        case "OPEN_ENTITY":
          if (action.action_button_entity_id && this.otherActionUrl) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No entity selected");
          }
          break;
        case "OPEN_ENTITY_VIEW":
          if (action.action_button_entity_view_id && this.otherActionUrl) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No entity view selected");
          }
          break;
      }
    },
    async action() {
      let relationshipActionExisted = this.actions.filter(
        (action) =>
          action.action_button_action_type == "ESTABLISH_RELATIONSHIP" ||
          action.action_button_action_type == "CREATE_DUPLICATE"
      );
      relationshipActionExisted.forEach((relationEntity) => {
        if (
          this.isFromMenuTemplate &&
          relationEntity.relationship_other_entity == "self_entity"
        ) {
          this.$message({
            message:
              "Can't Add. This button has been configured to add related data to self entity.",
            type: "warning",
          });
        }
        if (
          this.isFromMenuTemplate &&
          relationEntity.action_button_action_type == "CREATE_DUPLICATE"
        ) {
          this.$message({
            message:
              "Can't Duplicate. This button has been configured to duplicate the entity data.",
            type: "warning",
          });
        }
      });
      if (
        !this.fromQuickAction &&
        relationshipActionExisted?.length &&
        !this.$route?.query?.dataId &&
        !this.checkRelationshipsOnForm
      ) {
        this.$bus.$on("execute-action", async (dataId) => {
          this.data.parent_entityDataId = dataId;
          await this.checkEntityRelationships(
            this.data.parent_entityId,
            dataId
          );
          if (!this.currentDataId) {
            this.currentDataId = dataId;
          }
          this.executeAction();
        });
        this.$emit("saveTemplateFromActionButton");
      } else if (this.checkRelationshipsOnForm && !this.parent_entityDataId) {
        let k =
          this.checkRelationshipsOnForm.relationship_other_entity.split("#")[1];
        let field = this.fieldsData.find((f) => f.key == k);
        this.$message({
          message: "Warning, Please select " + (field?.label || "data"),
          type: "warning",
        });
      } else if (this.checkEditData && !this.parent_entityDataId) {
        let k = this.checkEditData.selected_other_entity_for_edit.split("#")[1];
        let field = this.fieldsData.find((f) => f.key == k);
        this.$message({
          message: "Warning, Please select " + (field?.label || "data"),
          type: "warning",
        });
      } else if (
        this.checkDownloadData &&
        (!this.parent_entityDataId || this.parent_entityDataId?.length == 0)
      ) {
        let k =
          this.checkDownloadData.selected_entity_for_download.split("#")[1];
        let field = this.fieldsData.find((f) => f.key == k);
        this.$message({
          message: "Warning, Please select " + (field?.label || "data"),
          type: "warning",
        });
      } else {
        this.executeAction();
      }
    },
    async executeAction() {
      if (this.actions && this.actions.length) {
        await (this.actions || []).forEach(async (action) => {
          switch (action.action_button_action_type) {
            case "TEMPLATE_ACTION":
              await this.applyFormRules();
              break;
            case "ESTABLISH_RELATIONSHIP":
              await this.updateRelationships();
              break;
            case "CREATE_DUPLICATE":
              await this.createDuplicateData(action);
              break;
            case "ADD_OTHER_ENTITY":
              await this.addOtherEntityData();
              break;
            case "EDIT_OTHER_ENTITY":
              await this.addOtherEntityData();
              break;
            case "DOWNLOAD_DATA":
              await this.downloadEntityData(action);
              break;
          }
        });
      } else {
        if (this.navigationAction) {
          this.checkNavigationAction(this.navigationAction);
        }
      }
      this.$bus.$off("execute-action");
    },
    afterAction(action) {
      this.$emit("actionButtonCallBacks", action.call_back);
    },
    async downloadEntityData(action) {
      this.loading = true;
      this.loadingText = "Generating document...";
      if (action?.selected_entity_for_download == "current_template") {
        await this.downloadCurrentTemplateData();
        this.loading = false;
      } else if (
        this.isFromMenuTemplate &&
        action?.selected_entity_for_download == "self_entity"
      ) {
        this.$message({
          message:
            "Can't download. This button has been configured to download data of the self entity.",
          type: "warning",
        });
        this.loading = false;
      } else {
        let entityId = action?.selected_entity_for_download?.includes("#")
          ? action.selected_entity_for_download.split("#")[0]
          : this.parent_entityId;
        await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
          entity_id: entityId,
        });
        if (this.getEntityById) {
          this.currentEntity = this.getEntityById;
        }
        // When it's self entity download, checking the entity data ID is created or not
        if (!this.parent_entityDataId) {
          this.$message({
            message: "This entity data haven't created, please click on save",
            type: "warning",
          });
          this.loading = false;
          return;
        }
        if (
          this.currentEntity &&
          this.currentEntity.default_printing_document &&
          this.parent_entityDataId
        ) {
          await this.$store.dispatch(
            "entitiesData/createDocumentFromEntityData",
            {
              entity_id: this.currentEntity._id,
              document_id: this.currentEntity.default_printing_document || "",
              ...(typeof this.parent_entityDataId == "object" &&
                this.parent_entityDataId?.length && {
                  entity_data_ids: this.parent_entityDataId,
                }),
              ...(typeof this.parent_entityDataId == "string" &&
                this.parent_entityDataId && {
                  entity_data_id: this.parent_entityDataId,
                }),
            }
          );
          if (
            this.getCreateDocumentForEntityData &&
            this.getCreateDocumentForEntityData.generatedDocuments
          ) {
            if (
              this.getCreateDocumentForEntityData.generatedDocuments?.length > 1
            ) {
              this.downloadMultipleEntityDataPdfs(
                this.getCreateDocumentForEntityData.generatedDocuments
              );
            } else {
              this.downloadEntityDataPdf(
                this.getCreateDocumentForEntityData.generatedDocuments,
                this.currentEntity && this.currentEntity.name
                  ? this.currentEntity.name
                  : "Data "
              );
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              message: "Error while generating document",
              type: "error",
            });
          }
        } else {
          this.loadingText = "";
          this.loading = false;
          this.$message({
            message:
              "Warning, No document template has been configured for " +
              this.currentEntity?.name,
            type: "warning",
          });
        }
      }
    },
    downloadMultipleEntityDataPdfs(pdfUrls) {
      const zip = new JSZip();
      const promises = [];

      pdfUrls.forEach((url, index) => {
        const filename = `document_${index + 1}.pdf`;
        promises.push(
          axios
            .get(url, { responseType: "blob" })
            .then((response) => zip.file(filename, response.data))
        );
      });

      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: "blob" }).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "documents.zip";
            a.click();
            window.URL.revokeObjectURL(url);
          });
        })
        .catch((error) => {
          console.log("Error while downloading files:", error);
        });
    },
    async downloadCurrentTemplateData() {
      try {
        this.loading = true;
        let hasNonEmptyValue = Object.keys(this.form).some((key) => {
          const value = this.form[key];
          return (
            value !== null &&
            value !== "" &&
            (!Array.isArray(value) || value.length > 0)
          );
        });
        if (hasNonEmptyValue) {
          let response = await postAPICall(
            "POST",
            "/entities-data/template-document-printing",
            {
              template_id: this.selfTemplateId,
              form: this.form,
            }
          );
          if (response?.generatedDocuments) {
            this.downloadEntityDataPdf(
              response.generatedDocuments,
              this.templateName ? this.templateName : "Data"
            );
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              message: "Error while generating document",
              type: "error",
            });
          }
        } else {
          this.$message({
            message: "Please fill at least one field",
            type: "warning",
          });
        }
        this.loading = false;
      } catch (err) {
        this.$message({
          message: "Please configure document template",
          type: "warning",
        });
      }
    },

    async addOtherEntityData() {
      this.loading = true;
      await this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
        entity_id: this.selectedOtherEntityId,
      });
      if (this.getEntityById) {
        this.currentEntity = this.getEntityById;
        this.openOtherEntityModal = true;
        this.isFromAddOtherEntity = true;
        this.loading = false;
      }
      this.loading = false;
    },
    resetOtherEntityModal() {
      this.openOtherEntityModal = false;
    },
    async createDuplicateData(action) {
      if (this.isView) {
        return this.$message.warning(
          "can't perform duplicate data action in view mode"
        );
      } else if (this.entity_type == "INDIVIDUAL") {
        return this.$message.warning(
          "can't duplicate data in individual entity"
        );
      }
      let fieldsDataObject = {};
      this.fieldsData.map((e) => {
        fieldsDataObject[`${e.template_id}#${e.key}`] = e;
      });
      let data = {
        entity_id: this.entityId,
        existed_data_id: this.currentDataId,
        current_date: this.getCurrentDate,
        selected_fields: action?.selected_fields || [],
        data_table_selected_rows: this.selectedTableRows,
        current_template_id: this.fieldsData?.[0]?.template_id,
      };
      let isValid = true;
      action.selected_fields.map((e) => {
        let field = fieldsDataObject[e];
        if (
          (field.input_type == "DATA_TABLE" ||
            field.inputType == "DATA_TABLE") &&
          !data.data_table_selected_rows[e]?.length
        ) {
          isValid = false;
          this.$message.warning(`Please select ${field.label} rows`);
        }
      });
      if (isValid) {
        this.$emit("loadComponent", {
          value: true,
          loadingText: "Creating New Data....",
        });
        await this.$store.dispatch(
          "templatesData/duplicateSelectedEntityData",
          data
        );
        if (this.getDuplicateSelectedEntityData?.success) {
          this.setSelectedTableRows();
          this.$notify.success({
            title: "Success",
            message: "Data Duplicated successfully",
          });
          await this.applyFormRules();
        } else {
          this.previewComponentLoading = false;
          this.$notify.error({
            title: "Error",
            message: this.getDuplicateSelectedEntityData.message,
          });
        }
        this.$emit("loadComponent", { value: false, loadingText: "" });
      }
    },
    setSelectedTableRows() {
      Object.keys(this.selectedTableRows).map(
        (key) => (this.selectedTableRows[key] = [])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 3px 0px 3px 0px !important;
  line-height: 20px;
}
</style>
